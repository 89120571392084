import * as React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
  Tooltip,
  Link,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { theme, useStyles } from "../style/template";

import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

import otherData from "../data/other.json";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Typography variant="h6" component="div">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({}));

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);
  const [indyConfirm, setIndyConfirm] = React.useState(false);

  const { state, setState } = props;
  const classes = useStyles();

  let ticketMultiplierText = "";
  for (let type in otherData["costMultiplier"]) {
    if (type !== "info")
      ticketMultiplierText +=
        type + " = " + otherData["costMultiplier"][type] + ", ";
  }
  ticketMultiplierText = ticketMultiplierText.slice(0, -2);

  let fuelPriceMultiplierText = "";
  for (let type in otherData["supplyCost"]) {
    if (type !== "info")
      fuelPriceMultiplierText +=
        type + " = " + otherData["supplyCost"][type] + ", ";
  }
  fuelPriceMultiplierText = fuelPriceMultiplierText.slice(0, -2);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{
          fontSize: 50,
          float: "right",
          marginTop: 20,
        }}
        color="primary"
        variant="outlined"
        type="submit"
        onClick={handleClickOpen}
      >
        <SettingsIcon />
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          StarScanner v4.11
        </BootstrapDialogTitle>
        <DialogContent dividers sx={{ p: 2 }}>
          <p>
            <strong>Settings:</strong>
          </p>

          <div className={classes.hideOnMobile}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.useNonReachableSystems}
                  onChange={(event) => {
                    setState({
                      ...state,
                      useNonReachableSystems: event.target.checked,
                    });
                  }}
                  color="success"
                />
              }
              label="Display out of reach systems"
            />
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Display out of reach systems
                  </Typography>
                  <p>
                    Controls whether to also show systems that are not reachable
                    from Sol with current human technology.
                  </p>
                </React.Fragment>
              }
            >
              <HelpOutlineOutlinedIcon
                style={{ color: "#aaa", fontSize: 16, marginLeft: -10 }}
              />
            </HtmlTooltip>
          </div>

          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={state.useRegionalTicketPrices}
                  onChange={(event) => {
                    setState({
                      ...state,
                      useRegionalTicketPrices: event.target.checked,
                    });
                  }}
                  color="success"
                />
              }
              label="Use regional prices"
            />
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Regional ticket prices
                  </Typography>
                  <p>
                    If turned on, passenger ticket prices are adjusted based on
                    the development level of the systems through which the
                    traveller is passing.
                  </p>
                  <p>
                    For each leg of the journey, the development statuses of the
                    two systems are looked at and the ticket price is multiplied
                    by a number that is the average of these two systems'
                    development multiplier. These multipliers are:{" "}
                    {ticketMultiplierText}
                  </p>
                </React.Fragment>
              }
            >
              <HelpOutlineOutlinedIcon
                style={{ color: "#aaa", fontSize: 16, marginLeft: -10 }}
              />
            </HtmlTooltip>
          </div>

          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={state.useVelocityDifferences}
                  onChange={(event) => {
                    setState({
                      ...state,
                      useVelocityDifferences: event.target.checked,
                    });
                  }}
                  color="success"
                />
              }
              label="Consider system velocities"
            />
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">System velocities</Typography>
                  <p>
                    Most stars in our galaxy move around the galactic center,
                    but they all do so with their own velocities and
                    trajectories. This introduces additional challenges to
                    stutterwarp travellers, as when a ship starts off from a
                    system it carries with it that system's stellar velocity,
                    which may differ greatly from the velocity of the target
                    system.
                  </p>
                  <p>
                    If this setting is turned on, the calculator takes into
                    account star velocities (based on Gliese Catalogue data) and
                    calculates the time that it takes the ship to match target
                    velocities using gravity breaking in a 0.1G gravity well,
                    resulting in a 3.53 km/s velocity change per hour.
                  </p>
                  <p>
                    Note that the ship does not need to match its velocity with
                    a system if it only needs to carry out a stutterwarp
                    discharge and has no need stop in the system for supply or
                    docking. Instead, it can proactively adjust its velocity to
                    a future target system where it needs to stop. In this case,
                    the gravity breaking is calculated as a 1.76 km/s velocity
                    change per hour.
                  </p>
                </React.Fragment>
              }
            >
              <HelpOutlineOutlinedIcon
                style={{ color: "#aaa", fontSize: 16, marginLeft: -10 }}
              />
            </HtmlTooltip>
          </div>
          <p className={classes.hideOnMobile}>
            <strong>Experimental settings:</strong>
          </p>
          <div className={classes.hideOnMobile}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.useMouseOver}
                  onChange={(event) => {
                    setState({
                      ...state,
                      useMouseOver: event.target.checked,
                    });
                  }}
                  color="success"
                />
              }
              label="Highlight connections when hovering over a system"
            />
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Highlight connections</Typography>
                  <p>
                    When your mouse cursor is over a system, connections to
                    other systems are highlighted. This can be a bit laggy.
                  </p>
                </React.Fragment>
              }
            >
              <HelpOutlineOutlinedIcon
                style={{ color: "#aaa", fontSize: 16, marginLeft: -10 }}
              />
            </HtmlTooltip>
          </div>
          <div className={classes.hideOnMobile}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.useIndy}
                  onChange={(event) => {
                    setState({
                      ...state,
                      useIndy: event.target.checked,
                    });
                  }}
                  color="success"
                />
              }
              label="Travel like Indy (and allow sharing data with YouTube)"
            />
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Travel like Indy</Typography>
                  <p>
                    This allows you to travel more like Indiana Jones. By
                    turning this on, you allow StarScanner to establish a
                    connection with YouTube, which will send personally
                    identifiable data to YouTube.
                  </p>
                </React.Fragment>
              }
            >
              <HelpOutlineOutlinedIcon
                style={{ color: "#aaa", fontSize: 16, marginLeft: -10 }}
              />
            </HtmlTooltip>
          </div>
          <p>
            <strong>Notes:</strong>
          </p>
          <p>
            This is a distance and travel calculator for the world of 2300AD.
            Its aim is to help users conceptualise the distances between the
            star systems that exist in physical, temporal and psychological
            sense.
          </p>
          <p>
            Star data, colony data, ship data, travel time formulas and
            passenger ticket prices were primarily taken from{" "}
            <Link href="https://www.mongoosepublishing.com/" target="_blank">
              Mongoose Publishing
            </Link>
            's first edition 2300AD publications, with that data supplemented
            with GDW's older 2300AD books and magazine articles where possible.
            For ships that only exist in GDW's publications, listed fuel tank
            tonnages are converted to one tenth in dtons, to approximate
            Mongoose's power plant tank sizes. Star system data has been updated
            to Mongoose's 2nd edition 2300AD, while a ship update will be done
            at some point in the future.
          </p>
          <p>
            StarScanner is in no way affiliated with Mongoose Publishing, GDW or
            anyone else. All errors, mistakes, implausibilities and general
            absurdities are my own.
          </p>
          <p>
            When an origin or destination system is selected, the automatically
            suggested shallows travel distance and the world gravity are for the
            system's main planet. Travel distances for discharge and supply are
            similarly taken from official books wherever possible. If no
            official information for these is available, made-up distances are
            used instead.
          </p>
          <p>
            Luggage is calculated in dkg, standing for displacement kilos (0.001
            dton). If luggage size given is more than the allowance for the
            passage type, extra luggage is calculated with the cargo cost of 750
            lv/dton.
          </p>
          <p>
            Berthing times and departure times are calculated based on the
            system's development level and law level, giving a time between 0.5
            and 6 hours or 0.5 and 3 hours, respectively.
          </p>
          <p>
            Customs, boarding and quarantine times are calculated based on the
            system's development level and law level, giving a time between 1h
            and 12h for outgoing traffic, and 1h and 336h (14 days) for incoming
            passengers and cargo.
          </p>
          <p>
            Fuel price is set at 500 lv / dton and multiplied by the system's
            development level: {fuelPriceMultiplierText}
          </p>
          <p>
            Fuelling time is calculated as 0.1 dton / minute when at a starport.
            For frontier fuelling times, the time is calculated based on the
            ships's fuel processor, plus 10 hours to find the materials needed.
          </p>
          <p>
            <strong>About:</strong>
          </p>
          <p>
            StarScanner doesn't use cookies or other delicious snacks that could
            affect your privacy or waistline. The app is built with{" "}
            <Link href="https://reactjs.org/" target="_blank">
              React
            </Link>
            ,{" "}
            <Link href="https://mui.com/" target="_blank">
              MUI
            </Link>
            ,{" "}
            <Link href="https://leafletjs.com/" target="_blank">
              Leaflet
            </Link>
            ,{" "}
            <Link href="https://react-leaflet.js.org/" target="_blank">
              React Leaflet
            </Link>
            ,{" "}
            <Link href="https://fontawesome.com/" target="_blank">
              Font Awesome
            </Link>
            ,{" "}
            <Link
              href="https://github.com/CookPete/react-player"
              target="_blank"
            >
              React Player
            </Link>{" "}
            and plenty of love for the world of 2300AD.
          </p>
          <p>
            If you have any questions or comments, or just want to say hi, send
            a message to vili@six51.com
          </p>
          <p>Safe travels,</p>
          <p>Vili</p>
        </DialogContent>
        <DialogActions sx={{ m: 0, p: 1 }}>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
