import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { ThemeProvider } from "@mui/material/styles";

import Settings from "./components/settings";
import Form from "./components/form";
import Submit from "./components/submit";
import ProgressBar from "./components/progress";
import Summary from "./components/summary";
import Itinerary from "./components/itinerary";
import Map from "./components/map";

import "./style/style.css";
import { theme, useStyles } from "./style/template";
import logo from "./images/starscanner-logo.png";

import defaultState from "./data/default";

function App() {
  const [state, setState] = React.useState(defaultState);
  const [pathProgress, setPathProgress] = React.useState(-1);
  const classes = useStyles();

  // Scroll to top when refreshed
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.mainContainer}>
        <div className={classes.formContainer}>
          {/* Header */}
          <Settings state={state} setState={setState} />
          <img className={classes.logo} src={logo} alt="StarScanner" />

          {/* Form */}
          <Form
            state={state}
            setState={setState}
            theme={theme}
            classes={classes}
          />
          {pathProgress < 0 && (
            <Submit
              state={state}
              setState={setState}
              setPathProgress={setPathProgress}
              classes={classes}
            />
          )}

          {/* Results */}
          {pathProgress >= 0 && <ProgressBar pathProgress={pathProgress} />}
          {state.error !== "" && (
            <div className={classes.resultsError}>{state.error}</div>
          )}
          <span id="pathScroll"></span>
          {state.path.length > 0 && (
            <div id="tripSummary">
              <h2 className={classes.resultsHeader}>summary</h2>
              <Summary state={state} />
              <h2 className={classes.resultsHeader}>itinerary</h2>
              <Itinerary
                state={state}
                setState={setState}
                theme={theme}
                classes={classes}
              />
            </div>
          )}
        </div>
        <div className={classes.mapContainer}>
          <Map state={state} setState={setState} />
        </div>
      </div>
    </ThemeProvider>
  );
}

ReactDOM.render(<App />, document.querySelector("#root"));
