import React from "react";
import _round from "lodash/round";
import _orderBy from "lodash/orderBy";

import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Autocomplete,
} from "@mui/material";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/lab";

import systemData from "../data/systems.json";
import otherData from "../data/other.json";
import shipData from "../data/ships.json";

export default function Form(props) {
  const { state, setState, theme } = props;

  // Ticket types
  let ticketTypes = [];
  for (let ticketType in otherData.ticketTypes) {
    if (ticketType !== "info") {
      ticketTypes.push({ name: ticketType });
    }
  }

  // Date setup
  const d = new Date(); // Today's date
  const d2300 = d.setFullYear(2299);
  const minDate = new Date("2280-01-01");
  const maxDate = new Date("2399-12-31");

  // Systems
  let systemsValues = [];
  let systemToCommon = [];
  for (let system in systemData) {
    if (
      state.useNonReachableSystems === true ||
      (state.useNonReachableSystems === false &&
        systemData[system].starGroup === systemData["Sol"].starGroup)
    ) {
      let commonName = "";
      if (systemData[system].commonNames)
        commonName = " / " + systemData[system].commonNames;
      systemsValues.push(system);
      systemToCommon[system] = system + commonName;
    }
  }
  systemsValues.sort();

  // Ships
  let shipList = [];
  for (let ship in shipData) {
    if (shipData[ship].stutterwarpLoaded) {
      let shipName = shipData[ship].name;
      if (shipData[ship].stutterwarpUnLoaded) shipName = shipName + " - loaded";
      shipList.push({
        name: shipName,
        value: ship,
        variant: "loaded",
      });
    }
    if (shipData[ship].stutterwarpUnLoaded)
      shipList.push({
        name: shipData[ship].name + " - unloaded",
        value: ship,
        variant: "unloaded",
      });
  }
  shipList = _orderBy(shipList, ["name"], ["asc"]);
  shipList.unshift({ name: "Custom", value: "custom" });

  // Interfaces Up
  let interfaceUpList = [];
  for (let interfaceUp in otherData.interfaceUp) {
    if (interfaceUp !== "info") {
      interfaceUpList.push({
        name: otherData.interfaceUp[interfaceUp].name,
        value: interfaceUp,
      });
    }
  }
  interfaceUpList = _orderBy(interfaceUpList, ["name"], ["asc"]);

  // Interfaces Down
  let interfaceDownList = [];
  for (let interfaceDown in otherData.interfaceDown) {
    if (interfaceDown !== "info") {
      interfaceDownList.push({
        name: otherData.interfaceDown[interfaceDown].name,
        value: interfaceDown,
      });
    }
  }
  interfaceDownList = _orderBy(interfaceDownList, ["name"], ["asc"]);

  return (
    <React.Fragment>
      {/* Date */}
      <div
        style={{
          paddingTop: 0,
          paddingBottom: 10,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Departure date"
                value={state.date}
                minDate={minDate}
                maxDate={maxDate}
                inputFormat="yyyy.MM.dd"
                mask="____.__.__"
                onChange={(newValue) => {
                  if (newValue === null) newValue = d2300;
                  newValue = new Date(newValue);
                  newValue = newValue.getTime();
                  setState({
                    ...state,
                    date: newValue,
                    path: [],
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="filled" helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={0} sm={1}>
            {" "}
          </Grid>
          <Grid item xs={12} sm={5} style={{ marginTop: 25 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.passengerTravel}
                  onChange={(event) => {
                    if (event.target.checked === true) {
                      setState({
                        ...state,
                        passengerTravel: event.target.checked,
                        path: [],
                      });
                    } else {
                      setState({
                        ...state,
                        passengerTravel: event.target.checked,
                        passengerType: null,
                        path: [],
                      });
                    }
                  }}
                  name="passengerTravel"
                  color="success"
                />
              }
              label="Buy tickets"
            />
          </Grid>
        </Grid>
        {state.passengerTravel && (
          <Grid container spacing={2} style={{ marginTop: 10 }}>
            <Grid xs={12} item sm={6}>
              <TextField
                id="ticketNumber"
                label="Number of tickets"
                variant="filled"
                type="number"
                value={state.passengerNumber}
                onChange={(event) => {
                  setState({
                    ...state,
                    passengerNumber: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="ticketType"
                autoHighlight={true}
                autoSelect={true}
                options={ticketTypes}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                defaultValue={ticketTypes[1]}
                onChange={(event, value) => {
                  if (value) {
                    setState({
                      ...state,
                      passengerType: value.name,
                      path: [],
                    });
                  } else {
                    setState({
                      ...state,
                      passengerType: null,
                      path: [],
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Ticket type" variant="filled" />
                )}
              />
            </Grid>
            <Grid item xs={8} sm={6}>
              <TextField
                id="passengerUpkeep"
                label="Upkeep"
                variant="filled"
                type="number"
                value={state.passengerUpkeep}
                onChange={(event) => {
                  setState({
                    ...state,
                    passengerUpkeep: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={4} sm={2} md={1} style={{ marginTop: 25 }}>
              lv/day
            </Grid>
            <Grid item xs={8} sm={6}>
              <TextField
                id="passengerCargo"
                label="Luggage"
                variant="filled"
                type="number"
                value={state.passengerCargo}
                onChange={(event) => {
                  setState({
                    ...state,
                    passengerCargo: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={4} sm={6} style={{ marginTop: 25 }}>
              dkg
            </Grid>
          </Grid>
        )}
      </div>
      {/* Origin system */}
      <h3
        style={{
          marginTop: 30,
          marginBottom: 10,
          color: theme.palette.primary.main,
        }}
      >
        From
      </h3>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="originSystem"
            autoHighlight={true}
            autoSelect={true}
            value={state.originSystem}
            options={systemsValues}
            getOptionLabel={(option) => systemToCommon[option]}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, value) => {
              if (value) {
                setState({
                  ...state,
                  originSystem: value,
                  originSystemShallows:
                    _round(systemData[value].supplyDistance, 2) || 0,
                  originSystemInterfaceG: systemData[value].mainWorldG || 0,
                  error: "",
                  path: [],
                });
              } else {
                setState({
                  ...state,
                  originSystem: null,
                  originSystemShallows: 0,
                  path: [],
                  error: "",
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Origin system" variant="filled" />
            )}
          />
        </Grid>
        {state.originSystem !== null && (
          <React.Fragment>
            <Grid item xs={6} sm={4}>
              <TextField
                id="originSystemShallows"
                label="Shallows travel"
                variant="filled"
                type="number"
                value={state.originSystemShallows}
                onChange={(event) => {
                  setState({
                    ...state,
                    originSystemShallows: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2} style={{ marginTop: 25 }}>
              AU
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
              {state.originSystemInterface === false ? (
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onClick={() =>
                    setState({
                      ...state,
                      originSystemInterface: true,
                      path: [],
                    })
                  }
                >
                  Add Interface
                </Button>
              ) : (
                <Button
                  color="warning"
                  variant="contained"
                  type="submit"
                  onClick={() =>
                    setState({
                      ...state,
                      originSystemInterface: false,
                      originSystemInterfaceTransport: null,
                      path: [],
                    })
                  }
                >
                  Remove Interface
                </Button>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {state.originSystemInterface && (
        <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 10 }}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="originSystemInterfaceTransport"
              autoHighlight={true}
              autoSelect={true}
              options={interfaceUpList}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, value) => {
                if (value) {
                  setState({
                    ...state,
                    originSystemInterfaceTransport: value.value,
                    path: [],
                  });
                } else {
                  setState({
                    ...state,
                    originSystemInterfaceTransport: null,
                    path: [],
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Interface transport type"
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              id="originInterfaceG"
              label="World gravity"
              variant="filled"
              type="number"
              value={state.originSystemInterfaceG}
              onChange={(event) => {
                setState({
                  ...state,
                  originSystemInterfaceG: event.target.value,
                  path: [],
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={1} style={{ marginTop: 25 }}>
            G
          </Grid>
          <Grid item xs={6} sm={7} style={{ marginTop: 15 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.originSystemHasAtmosphere}
                  onChange={(event) => {
                    setState({
                      ...state,
                      originSystemHasAtmosphere: event.target.checked,
                      path: [],
                    });
                  }}
                  name="originSystemHasAtmosphere"
                  color="success"
                />
              }
              label="Has atmosphere"
            />
          </Grid>
        </Grid>
      )}
      {/* Destination system */}
      <h3
        style={{
          marginTop: 40,
          marginBottom: 10,
          color: theme.palette.primary.main,
        }}
      >
        To
      </h3>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="destinationSystem"
            value={state.destinationSystem}
            autoHighlight={true}
            autoSelect={true}
            options={systemsValues}
            getOptionLabel={(option) => systemToCommon[option]}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, value) => {
              if (value) {
                setState({
                  ...state,
                  destinationSystem: value,
                  destinationSystemShallows:
                    _round(systemData[value].supplyDistance, 2) || 0,
                  destinationSystemInterfaceG:
                    systemData[value].mainWorldG || 0,
                  error: "",
                  path: [],
                });
              } else {
                setState({
                  ...state,
                  destinationSystem: null,
                  destinationSystemShallows: 0,
                  path: [],
                  error: "",
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Destination system"
                variant="filled"
              />
            )}
          />
        </Grid>
        {state.destinationSystem !== null && (
          <React.Fragment>
            <Grid item xs={6} sm={4}>
              <TextField
                id="destinationSystemShallows"
                label="Shallows travel"
                variant="filled"
                type="number"
                value={state.destinationSystemShallows}
                onChange={(event) => {
                  setState({
                    ...state,
                    destinationSystemShallows: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2} style={{ marginTop: 25 }}>
              AU
            </Grid>
            <Grid item xs={12} sm={6} style={{ marginTop: 15 }}>
              {state.destinationSystemInterface === false ? (
                <Button
                  color="secondary"
                  variant="contained"
                  type="submit"
                  onClick={() =>
                    setState({
                      ...state,
                      destinationSystemInterface: true,
                      path: [],
                    })
                  }
                >
                  Add Interface
                </Button>
              ) : (
                <Button
                  color="warning"
                  variant="contained"
                  type="submit"
                  onClick={() =>
                    setState({
                      ...state,
                      destinationSystemInterface: false,
                      destinationSystemInterfaceTransport: null,
                      path: [],
                    })
                  }
                >
                  Remove Interface
                </Button>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {state.destinationSystemInterface && (
        <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 10 }}>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="destinationSystemInterfaceTransport"
              options={interfaceDownList}
              autoHighlight={true}
              autoSelect={true}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(event, value) => {
                if (value) {
                  setState({
                    ...state,
                    destinationSystemInterfaceTransport: value.value,
                    path: [],
                  });
                } else {
                  setState({
                    ...state,
                    destinationSystemInterfaceTransport: null,
                    path: [],
                  });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Interface transport type"
                  variant="filled"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <TextField
              id="destinationInterfaceG"
              label="World gravity"
              variant="filled"
              type="number"
              value={state.destinationSystemInterfaceG}
              onChange={(event) => {
                setState({
                  ...state,
                  destinationSystemInterfaceG: event.target.value,
                  path: [],
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={1} style={{ marginTop: 25 }}>
            G
          </Grid>
          <Grid item xs={6} sm={7} style={{ marginTop: 15 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.destinationSystemHasAtmosphere}
                  onChange={(event) => {
                    setState({
                      ...state,
                      destinationSystemHasAtmosphere: event.target.checked,
                      path: [],
                    });
                  }}
                  name="destinationSystemHasAtmosphere"
                  color="success"
                />
              }
              label="Has atmosphere"
            />
          </Grid>
        </Grid>
      )}
      {/* Ship details */}
      <h3
        style={{
          marginTop: 40,
          marginBottom: 10,
          color: theme.palette.primary.main,
        }}
      >
        Ship
      </h3>
      <Grid container spacing={1} style={{ marginBottom: 10 }}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="ship"
            options={shipList}
            autoHighlight={true}
            autoSelect={true}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(event, value) => {
              if (value) {
                if (value.value === "custom") {
                  setState({
                    ...state,
                    shipType: value.value,
                    path: [],
                  });
                } else {
                  setState({
                    ...state,
                    shipType: value.value,
                    shipWarpEfficiency:
                      value.variant === "loaded"
                        ? shipData[value.value].stutterwarpLoaded
                        : shipData[value.value].stutterwarpUnLoaded,
                    shipSupply: shipData[value.value].supply,
                    shipSupplyTank: shipData[value.value].supplyTank,
                    shipFuelProcessor: shipData[value.value].fuelProcessor,
                    path: [],
                  });
                }
              } else {
                setState({
                  ...state,
                  shipType: null,
                  path: [],
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Ship type" variant="filled" />
            )}
          />
        </Grid>
        {state.shipType !== null && (
          <React.Fragment>
            <Grid item xs={6} sm={4}>
              <TextField
                id="shipWarpEfficiency"
                label="Warp efficiency"
                variant="filled"
                type="number"
                value={state.shipWarpEfficiency}
                onChange={(event) => {
                  setState({
                    ...state,
                    shipWarpEfficiency: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2} style={{ marginTop: 25 }}>
              ly/day
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                id="shipDischarge"
                label="Discharge efficiency"
                variant="filled"
                type="number"
                value={state.shipDischarge}
                onChange={(event) => {
                  setState({
                    ...state,
                    shipDischarge: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2} style={{ marginTop: 25 }}>
              hours/ly
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                id="shipSupply"
                label="Supply length"
                variant="filled"
                type="number"
                value={state.shipSupply}
                onChange={(event) => {
                  setState({
                    ...state,
                    shipSupply: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2} style={{ marginTop: 25 }}>
              days
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                id="shipSupplyTank"
                label="Supply tank size"
                variant="filled"
                type="number"
                value={state.shipSupplyTank}
                onChange={(event) => {
                  setState({
                    ...state,
                    shipSupplyTank: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2} style={{ marginTop: 25 }}>
              dton
            </Grid>
            <Grid item xs={6} sm={4}>
              <TextField
                id="shipFuelProcessor"
                label="Fuel processor"
                variant="filled"
                type="number"
                value={state.shipFuelProcessor}
                onChange={(event) => {
                  setState({
                    ...state,
                    shipFuelProcessor: event.target.value,
                    path: [],
                  });
                }}
              />
            </Grid>
            <Grid item xs={6} sm={2} style={{ marginTop: 25 }}>
              dton/day
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {/* Avoid */}
      <h3
        style={{
          marginTop: 40,
          marginBottom: 10,
          color: theme.palette.primary.main,
        }}
      >
        Avoid
      </h3>
      <Grid item xs={12} sm={12}>
        <Autocomplete
          id="avoidSystems"
          multiple
          options={systemsValues}
          autoHighlight={true}
          autoSelect={true}
          getOptionLabel={(option) => systemToCommon[option]}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onChange={(event, value) => {
            if (value) {
              let avoid = [];
              for (let i = 0; i < value.length; i++) {
                avoid.push(value[i]);
              }
              setState({
                ...state,
                avoidSystems: avoid,
                path: [],
              });
            } else {
              setState({
                ...state,
                avoidSystems: [],
                path: [],
              });
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label="Systems to avoid" variant="filled" />
          )}
        />
      </Grid>
    </React.Fragment>
  );
}
