const d = new Date(); // Today's date
const d2300 = d.setFullYear(2299);

const state = {
  useNonReachableSystems: false,
  useRegionalTicketPrices: true,
  useRegionalUpkeepPrices: true,
  useVelocityDifferences: true,
  useMouseOver: false,
  useIndy: false,
  date: d2300,
  originSystem: null,
  originSystemShallows: 0,
  originSystemInterface: false,
  originSystemInterfaceTransport: null,
  originSystemInterfaceG: 0,
  originSystemHasAtmosphere: true,
  destinationSystem: null,
  destinationSystemShallows: 0,
  destinationSystemInterface: false,
  destinationSystemInterfaceTransport: null,
  destinationSystemInterfaceG: 0,
  destinationSystemHasAtmosphere: true,
  shipType: null,
  shipWarpEfficiency: 0,
  shipDischarge: 6,
  shipSupply: 0,
  shipSupplyTank: 0,
  shipFuelProcessor: 0,
  passengerTravel: false,
  passengerNumber: 1,
  passengerType: "Economy",
  passengerCargo: 20,
  passengerUpkeep: 50,
  avoidSystems: [],
  pathProgress: -1,
  path: [],
  error: "",
};

export default state;
