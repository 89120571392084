import React from "react";
import _round from "lodash/round";

import { TextField, Grid } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlaneDeparture as IconInterfaceUp } from "@fortawesome/free-solid-svg-icons/faPlaneDeparture";
import { faPlaneArrival as IconInterfaceDown } from "@fortawesome/free-solid-svg-icons/faPlaneArrival";
import { faPeopleArrows as IconCustomsOut } from "@fortawesome/free-solid-svg-icons/faPeopleArrows";
import { faPeopleArrows as IconCustomsIn } from "@fortawesome/free-solid-svg-icons/faPeopleArrows";
import { faSignOutAlt as IconUndocking } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faSignInAlt as IconDocking } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import { faRocket as IconShallowsOut } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faRocket as IconShallowsIn } from "@fortawesome/free-solid-svg-icons/faRocket";
import { faAngleDoubleRight as IconFTL } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faBiohazard as IconDischarge } from "@fortawesome/free-solid-svg-icons/faBiohazard";
import { faSyncAlt as IconVelocityMatch } from "@fortawesome/free-solid-svg-icons/faSyncAlt";
import { faCubes as IconSupply } from "@fortawesome/free-solid-svg-icons/faCubes";
import { faSolarPanel as IconFrontierSupply } from "@fortawesome/free-solid-svg-icons/faSolarPanel";

import systemData from "../data/systems.json";

import { getStarInfo } from "../helpers";

export default function Itinerary(props) {
  const { state, setState, classes } = props;

  // Date
  let tmpDate;
  let dateDisplayOptions = { year: "numeric", month: "long", day: "numeric" };

  return (
    <Grid
      container
      spacing={1}
      style={{ marginTop: 0, paddingLeft: 10, paddingRight: 10 }}
    >
      {state.path.map((leg, i) => {
        if (i === 0) tmpDate = state.date;
        let currentSystem = "";
        let currentSystemInfo = "";
        if (
          leg.system &&
          (i === 0 || leg.system !== state.path[i - 1].system)
        ) {
          let systemName = leg.system;
          if (systemData[leg.system] && systemData[leg.system].commonNames)
            systemName =
              systemName + " / " + systemData[systemName].commonNames;

          let systemTime = leg.time;
          for (let ii = i + 1; ii < state.path.length; ii++) {
            if (state.path[ii].system === leg.system) {
              systemTime = systemTime + state.path[ii].time;
            } else {
              break;
            }
          }

          // Convert systemTime to milliseconds
          systemTime = systemTime * 60 * 60 * 1000;
          let tmpFromDate = new Date(tmpDate);
          tmpDate = tmpDate + systemTime;
          let tmpToDate = new Date(tmpDate);

          currentSystem = (
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                <h3 className={classes.itinerarySystem}>{systemName}</h3>
              </Grid>
              <Grid item xs={12} sm={12}>
                <p className={classes.itineraryDate}>
                  {tmpFromDate.toLocaleDateString("en-GB", dateDisplayOptions)}{" "}
                  - {tmpToDate.toLocaleDateString("en-GB", dateDisplayOptions)}
                </p>
              </Grid>
            </React.Fragment>
          );

          if (leg.activity !== "FTL") {
            let starInfo = getStarInfo(systemData[leg.system].spectralType);
            let developmentStatus = systemData[leg.system].development;
            developmentStatus =
              developmentStatus.charAt(0).toUpperCase() +
              developmentStatus.slice(1) +
              " system";
            let nationsText = systemData[leg.system].nations;
            if (nationsText)
              nationsText =
                " for " + nationsText.replace(/,(?=[^,]*$)/, " and");
            currentSystemInfo = (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.itinerarySystemInfo}
              >
                {starInfo}. {developmentStatus}
                {nationsText}.
              </Grid>
            );
          } else {
            currentSystemInfo = (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.itinerarySystemInfo}
                style={{ marginBottom: 0 }}
              >
                {" "}
              </Grid>
            );
          }
        }

        // Set content
        let icon;
        let iconRotation = 0;
        let title = "";
        let content = "";
        switch (leg.activity) {
          case "interfaceUp":
            icon = IconInterfaceUp;
            title = "Interface connection up";
            let atmosphereText = leg.atmosphere
              ? "with atmosphere."
              : "with no atmosphere.";
            let priceText =
              leg.price && leg.price > 0
                ? " Price: " +
                  new Intl.NumberFormat("en-GB").format(_round(leg.price)) +
                  " lv."
                : "";
            content =
              leg.type +
              ", " +
              leg.interfaceG +
              "g " +
              atmosphereText +
              priceText;
            break;
          case "interfaceDown":
            icon = IconInterfaceDown;
            title = "Interface connection down";
            let atmosphereText2 = leg.atmosphere
              ? "with atmosphere"
              : "with no atmosphere";
            let priceText2 =
              leg.price && leg.price > 0
                ? " Price: " +
                  new Intl.NumberFormat("en-GB").format(_round(leg.price)) +
                  " lv."
                : "";
            content =
              leg.type +
              ", " +
              leg.interfaceG +
              "g " +
              atmosphereText2 +
              priceText2;
            break;
          case "customsOut":
            icon = IconCustomsOut;
            title = "Departure customs and boarding";
            let developmentStatus = systemData[leg.system].development;
            developmentStatus =
              developmentStatus.charAt(0).toUpperCase() +
              developmentStatus.slice(1);
            let lawLevel = systemData[leg.system].lawLevel || 0;
            content =
              developmentStatus +
              " system with a law level of " +
              lawLevel +
              ".";
            break;
          case "customsIn":
            icon = IconCustomsIn;
            title = "Arrival customs and quarantine";
            let developmentStatus2 = systemData[leg.system].development;
            developmentStatus2 =
              developmentStatus2.charAt(0).toUpperCase() +
              developmentStatus2.slice(1);
            let lawLevel2 = systemData[leg.system].lawLevel || 0;
            content =
              developmentStatus2 +
              " system with a law level of " +
              lawLevel2 +
              ".";
            break;
          case "undocking":
            icon = IconUndocking;
            title = "Setting sail";
            break;
          case "docking":
            icon = IconDocking;
            title = "Berthing";
            break;
          case "shallowsOut":
            icon = IconShallowsOut;
            title = "Departing through the shallows";
            content =
              leg.distance > 0.1
                ? _round(leg.distance, 2) + " au."
                : "Less than 0.1 au.";
            break;
          case "shallowsIn":
            icon = IconShallowsIn;
            iconRotation = 90;
            title = "Entering through the shallows";
            content =
              leg.distance > 0.1
                ? _round(leg.distance, 2) + " au."
                : "Less than 0.1 au.";
            break;
          case "FTL":
            icon = IconFTL;
            title = "FTL travel";
            content =
              leg.distance > 0.1
                ? _round(leg.distance, 2) + " light years in the deeps."
                : "Less than 0.1 light years in the deeps.";
            if (leg.supplyLeft < 0) {
              content =
                content +
                " Life support system out of fuel!!! You need to pack extra.";
            } else if (leg.supplyLeft < 50) {
              content = content + " Life support system running low on fuel.";
            }
            let priceText3 =
              leg.price && leg.price > 0
                ? " Price of this leg: " +
                  new Intl.NumberFormat("en-GB").format(_round(leg.price)) +
                  " lv."
                : "";
            if (priceText3 !== "") content = content + priceText3;
            break;
          case "discharge":
            icon = IconDischarge;
            title = "Engine discharge";
            if (leg.velocityTarget) {
              content =
                "Proactively adjusting velocity for " +
                leg.velocityTarget +
                ".";
            } else if (leg.partial) {
              content = "Continuing discharge.";
            }
            break;
          case "velocityMatch":
            icon = IconVelocityMatch;
            title = "Matching current system velocity";
            content =
              leg.amount +
              " km/s velocity " +
              String.fromCharCode(916) +
              " to equalise.";
            break;
          case "supply":
            icon = IconSupply;
            title = "Refuelling and supply";
            content = _round(leg.amount, 2) + " dton of fuel added.";
            if (leg.price && leg.price > 0)
              content =
                content +
                " Price: " +
                new Intl.NumberFormat("en-GB").format(_round(leg.price)) +
                " lv.";
            content = content + " Engine discharge started.";
            break;
          case "frontierRefuel":
            icon = IconFrontierSupply;
            title = "Frontier refuel";
            content = "Tanks filled using fuel processor. Engine discharge.";
            break;
          default:
            break;
        }

        return (
          <React.Fragment key={i}>
            {currentSystem}
            {currentSystemInfo}
            <Grid item xs={12} sm={2}>
              <FontAwesomeIcon
                icon={icon}
                rotation={iconRotation}
                className={classes.itineraryIcon}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <p className={classes.itineraryTitle}>{title}</p>
              <p className={classes.itineraryContent}>{content}</p>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                id="time"
                label="Time (h)"
                variant="filled"
                type="number"
                value={leg.time}
                className={classes.itineraryHours}
                onChange={(event) => {
                  let tmpPath = state.path;
                  tmpPath[i].time = parseFloat(event.target.value);
                  setState({
                    ...state,
                    path: tmpPath,
                  });
                }}
              />
            </Grid>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}
