import React from "react";
import { Button } from "@mui/material";

const worker = new Worker(new URL("../pathFinder/index.js", import.meta.url));

let currentProgress;

export default function Submit({ state, setState, setPathProgress }) {
  worker.onmessage = ({ data: { error, status, finalPath } }) => {
    if (error) {
      setState({
        ...state,
        error: error,
        pathProgress: -1,
      });
      document.getElementById("pathScroll").scrollIntoView();
    }
    if (status) {
      if (currentProgress < status) {
        currentProgress = status;
        setPathProgress(currentProgress);
      }
    }
    if (finalPath) {
      setState({ ...state, path: finalPath, pathProgress: 100 });
      document.getElementById("pathScroll").scrollIntoView();
      setPathProgress(-1);
    }
  };

  const handleClick = async () => {
    currentProgress = 0;
    setState({ ...state, path: [], pathProgress: 0 });
    setPathProgress(-1);
    worker.postMessage({ state });
  };

  // Submit button visibility
  const isSubmitAvailable =
    state.date !== null &&
    state.originSystem !== null &&
    state.destinationSystem !== null &&
    state.shipType !== null &&
    (state.originSystemInterface === false ||
      (state.originSystemInterface === true &&
        state.originSystemInterfaceTransport !== null)) &&
    (state.destinationSystemInterface === false ||
      (state.destinationSystemInterface === true &&
        state.destinationSystemInterfaceTransport !== null)) &&
    (state.passengerTravel === false ||
      (state.passengerTravel === true && state.passengerType !== null))
      ? true
      : false;

  return (
    <div style={{ marginTop: 40 }}>
      {isSubmitAvailable ? (
        <Button
          color="secondary"
          variant="contained"
          type="submit"
          style={{ marginTop: 20 }}
          onClick={() => {
            handleClick();
          }}
        >
          Plot itinerary
        </Button>
      ) : (
        <Button disabled variant="contained" style={{ marginTop: 20 }}>
          Plot itinerary
        </Button>
      )}
    </div>
  );
}
