export const getStarInfo = (spectralType) => {
  // Brightness
  let brightness = "Regular";
  if (spectralType[1] < 3) brightness = "Bright";
  if (spectralType[1] > 7) brightness = "Dim";
  // Color
  let color = "yellow";
  switch (spectralType[0]) {
    case "v":
    case "a":
      color = "blue";
      break;
    case "f":
      color = "white";
      break;
    case "g":
      color = "yellow";
      break;
    case "k":
      color = "orange";
      break;
    case "c":
    case "m":
      color = "red";
      break;
    default:
      color = "yellow";
      break;
  }
  // Type
  let type = "star";
  switch (spectralType.substr(2)) {
    case "iii":
      type = "giant";
      break;
    case "iv":
      type = "sub-giant";
      break;
    case "v":
      type = "star";
      break;
    case "vi":
      type = "sub-dwarf";
      break;
    case "vii":
      type = "dwarf";
      break;
    default:
      type = "star";
      break;
  }
  return brightness + " " + color + " " + type;
};
