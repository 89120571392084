import React from "react";
import _round from "lodash/round";
import { Grid } from "@mui/material";

let dateDisplayOptions = { year: "numeric", month: "long", day: "numeric" };

export default function Summary(props) {
  let state = props.state;

  let totalTime = 0;
  let totalJumps = 0;
  let totalSupplies = 0;
  let totalLightyears = 0;
  let totalCost = 0;
  let totalFTLtime = 0;
  let totalSubFTLtime = 0;
  let totalStationaryTime = 0;
  for (let i = 0; i < state.path.length; i++) {
    if (state.path[i].time) totalTime += state.path[i].time;
    if (state.path[i].activity === "FTL") {
      totalJumps++;
      totalLightyears = totalLightyears + state.path[i].distance;
      totalFTLtime = totalFTLtime + state.path[i].time;
    } else if (state.path[i].activity === "supply") {
      totalSupplies++;
      totalStationaryTime = totalStationaryTime + state.path[i].time;
    } else if (
      state.path[i].activity === "discharge" ||
      state.path[i].activity === "customsIn" ||
      state.path[i].activity === "customsOut"
    ) {
      totalStationaryTime = totalStationaryTime + state.path[i].time;
    } else {
      totalSubFTLtime = totalSubFTLtime + state.path[i].time;
    }
    if (state.path[i].price) totalCost = totalCost + state.path[i].price;
  }

  if (state.passengerType === "Frozen Sleep") totalCost = totalCost + 2000; // Frozen sleep cost

  if (state.passengerTravel)
    totalCost = totalCost + (totalTime / 24) * state.passengerUpkeep;
  let departureDate = new Date(state.date);
  let arrivalDate = new Date(state.date + totalTime * 60 * 60 * 1000);

  return (
    <React.Fragment>
      <Grid
        container
        style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>Departure date</p>
          <p style={{ marginTop: "0px" }}>
            {departureDate.toLocaleDateString("en-GB", dateDisplayOptions)}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>Arrival date</p>
          <p style={{ marginTop: "0px" }}>
            {arrivalDate.toLocaleDateString("en-GB", dateDisplayOptions)}
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>Total time</p>
          <p style={{ marginTop: "0px" }}>{_round(totalTime / 24)} days</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>Total distance</p>
          <p style={{ marginTop: "0px" }}>
            {_round(totalLightyears, 1)} light years
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>
            {state.passengerTravel === true ? "Tickets & upkeep" : "Fuel costs"}
          </p>
          <p style={{ marginTop: "0px" }}>
            {new Intl.NumberFormat("en-GB").format(_round(totalCost))} lv
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: 0, paddingLeft: 10, paddingRight: 10 }}
      >
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>FTL jumps</p>
          <p style={{ marginTop: "0px" }}>{totalJumps}</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>Supply stops</p>
          <p style={{ marginTop: "0px" }}>{totalSupplies}</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>FTL time</p>
          <p style={{ marginTop: "0px" }}>{_round(totalFTLtime / 24)} days</p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>SubFTL time</p>
          <p style={{ marginTop: "0px" }}>
            {_round(totalSubFTLtime / 24)} days
          </p>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontWeight: 800, marginBottom: "5px" }}>
            Stationary time
          </p>
          <p style={{ marginTop: "0px" }}>
            {_round(totalStationaryTime / 24)} days
          </p>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
