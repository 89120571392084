import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

// Main theme
export const theme = createTheme({
  palette: {
    primary: { main: "#111111" },
    secondary: { main: "#2196F3", contrastText: "#fff" },
    warning: { main: "#EF5350" },
    success: { main: "#4CAF50" },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderBottomLeftRadius: 16,
          borderTopRightRadius: 16,
        },
      },
    },
  },
});

// Component styles
export const useStyles = makeStyles({
  // Maincontainer
  mainContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  // Header
  formContainer: {
    padding: "30px",
    boxSizing: "border-box",
    flex: "0 0 500px",
    height: "100vh",
    paddingBottom: "100px",
    borderRight: "1px solid #666",
    overflow: "scroll",
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
      height: "auto",
      overflow: "auto",
    },
  },
  logo: {
    width: 300,
    maxWidth: "100%",
    height: "auto",
  },
  // Results
  resultsError: {
    marginTop: 30,
    background: "rgb(254, 239, 179)",
    color: "rgb(159, 96, 0)",
    padding: 20,
  },
  resultsHeader: {
    color: "#ffffff",
    background: "#333",
    marginTop: 60,
    padding: 5,
    paddingLeft: 15,
    fontSize: 20,
    fontWeight: 600,
    fontVariant: "small-caps",
    clipPath: "polygon(0 0, 100% 0%, 98% 100%, 0 100%)",
  },
  // Itinerary
  ad: {
    boxSizing: "border-box",
    width: "100%",
    marginTop: 40,
    marginLeft: "auto",
    marginBottom: 50,
    marginRight: "auto",
    textAlign: "left",
    border: "1px dotted #333",
    background: "#fcfcfc",
    padding: "30px 60px",
  },
  // Itinerary
  itinerarySystem: {
    color: theme.palette.primary.main,
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginBottom: 0,
      marginTop: 60,
    },
  },
  itineraryDate: {
    color: theme.palette.primary.main,
    fontStyle: "italic",
    fontWeight: 600,
    textAlign: "left",
    marginTop: 0,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginTop: -2,
    },
  },
  itinerarySystemInfo: {
    marginTop: 0,
    paddingTop: 15,
    marginBottom: 10,
    fontStyle: "italic",
    fontWeight: 600,
    borderTop: "1px solid #333",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  itineraryIcon: {
    color: theme.palette.primary.main,
    fontSize: 30,
    marginTop: 15,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingTop: 30,
      display: "block",
      margin: "0 auto",
    },
  },
  itineraryTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    marginBottom: 5,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      marginTop: 0,
    },
  },
  itineraryContent: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    marginTop: 0,
    fontStyle: "italic",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  itineraryHours: {
    marginTop: 10,
    marginRight: 20,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  // Map
  mapContainer: {
    flex: "1",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  // Mobile hide
  hideOnMobile: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});
