import { useState, useEffect } from "react";
import Leaflet from "leaflet";
import {
  MapContainer,
  CircleMarker,
  Tooltip,
  Polyline,
  Pane,
  Marker,
  Popup,
} from "react-leaflet";
import _round from "lodash/round";
import ReactPlayer from "react-player";

import systems from "../data/systems.json";

import "leaflet/dist/leaflet.css";
Leaflet.Icon.Default.imagePath = "../node_modules/leaflet";
delete Leaflet.Icon.Default.prototype._getIconUrl;
Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const worker = new Worker(new URL("../pathFinder/indy.js", import.meta.url));

export default function Map({ state, setState }) {
  const [map, setMap] = useState(null);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [hoverPaths, setHoverPaths] = useState([]);
  const [pathPaths, setPathPaths] = useState([]);
  const [videoPlaying, setVideoPlaying] = useState(false);

  useEffect(() => {
    setState({ ...state, path: [] });
    if (state.originSystem) {
      setFrom({
        position: [
          systems[state.originSystem].y,
          systems[state.originSystem].x,
        ],
      });
    } else {
      setFrom(null);
    }
  }, [state.originSystem]);

  useEffect(() => {
    setState({ ...state, path: [] });
    if (state.destinationSystem) {
      setTo({
        position: [
          systems[state.destinationSystem].y,
          systems[state.destinationSystem].x,
        ],
      });
    } else {
      setTo(null);
    }
  }, [state.destinationSystem]);

  useEffect(() => {
    if (state.path == null || state.path.length === 0) {
      if (pathPaths.length > 0)
        worker.postMessage({ message: "stop", path: [] });
      setPathPaths([]);
    } else if (state.useIndy === false) {
      {
        setPathPaths([]);
        let tmpPathPaths = [];
        let previousSystem = state.originSystem;
        for (let i = 0; i < state.path.length; i++) {
          let currentSystem = state.path[i].system;
          if (systems[currentSystem]) {
            if (previousSystem !== currentSystem) {
              tmpPathPaths.push(
                <Polyline
                  positions={[
                    [systems[previousSystem].y, systems[previousSystem].x],
                    [systems[currentSystem].y, systems[currentSystem].x],
                  ]}
                  color={"#50A3E9"}
                  key={
                    "pathpath_" +
                    previousSystem.replace(/\s+/g, "") +
                    currentSystem.replace(/\s+/g, "")
                  }
                  weight={3}
                >
                  <Tooltip pane={"tooltip"} offset={[10, 0]}>
                    <b>
                      {previousSystem} <>&#8596;</> {currentSystem}
                    </b>
                    <br />
                    {systems[previousSystem].connections[currentSystem].toFixed(
                      2
                    )}{" "}
                    ly
                    <br />
                    <>&#916; </>
                    {Math.round(
                      Math.sqrt(
                        (systems[previousSystem].u -
                          systems[currentSystem].u) **
                          2 +
                          (systems[previousSystem].v -
                            systems[currentSystem].v) **
                            2 +
                          (systems[previousSystem].w -
                            systems[currentSystem].w) **
                            2
                      )
                    )}{" "}
                    m/s
                  </Tooltip>
                </Polyline>
              );
              previousSystem = currentSystem;
            }
          }
          setPathPaths(tmpPathPaths);
        }
      }
    } else {
      if (state.path !== []) {
        worker.postMessage({ message: "start", path: state.path });
        setVideoPlaying(true);
      }
    }
  }, [state.path]);

  worker.onmessage = ({ data: { error, partialLeg, fullLeg, finished } }) => {
    if (error) {
      console.log(error);
    } else if (partialLeg) {
      if (videoPlaying === true) {
        let tmpPath = (
          <Polyline
            positions={partialLeg}
            color={"#F72A0E"}
            key={"indypathpath_" + pathPaths.length}
            weight={4}
          ></Polyline>
        );
        let tmpPaths = pathPaths.slice(0, -1);
        setPathPaths([...tmpPaths, tmpPath]);
      } else {
        setPathPaths([]);
      }
    } else if (fullLeg) {
      if (videoPlaying === true) {
        let tmpPath = (
          <Polyline
            positions={fullLeg}
            color={"#F72A0E"}
            key={"indypathpath_" + pathPaths.length}
            weight={4}
          ></Polyline>
        );
        let tmpPaths = pathPaths.slice(0, -1);
        setPathPaths([...tmpPaths, tmpPath, []]);
      }
    } else if (finished) {
      setVideoPlaying(false);
    }
  };

  const setMarker = (newSystem) => {
    map.closePopup();
    if (from === null) {
      setState({
        ...state,
        originSystem: newSystem,
        originSystemShallows: _round(systems[newSystem].supplyDistance, 2) || 0,
        originSystemInterfaceG: systems[newSystem].mainWorldG || 0,
        path: [],
        error: "",
      });
    } else if (to === null) {
      setState({
        ...state,
        destinationSystem: newSystem,
        destinationSystemShallows:
          _round(systems[newSystem].supplyDistance, 2) || 0,
        destinationSystemInterfaceG: systems[newSystem].mainWorldG || 0,
        path: [],
        error: "",
      });
    } else {
      setState({
        ...state,
        originSystem: newSystem,
        destinationSystem: null,
        error: "",
      });
    }
  };

  const mouseOverSystem = (e, system) => {
    let newHoverPaths = [];
    if (state.useMouseOver === true) {
      if (systems[system].connections) {
        for (let connection in systems[system].connections) {
          newHoverPaths.push(
            <Polyline
              positions={[
                [systems[system].y, systems[system].x],
                [systems[connection].y, systems[connection].x],
              ]}
              color={"#50A3E9"}
              key={
                "hoverpath_" +
                system.replace(/\s+/g, "") +
                connection.replace(/\s+/g, "")
              }
              weight={3}
            ></Polyline>
          );
        }
      }
    }
    setHoverPaths(newHoverPaths);
  };

  const mouseOutSystem = (e) => {
    setHoverPaths([]);
  };

  let colonyMarkers = [];
  let nonColonyMarkers = [];
  let nonReachableMarkers = [];
  let colonyPaths = [];
  let nonColonyPaths = [];
  let nonReachablePaths = [];
  let doneSystems = [];
  let donePaths = [];

  for (let system in systems) {
    if (
      doneSystems.indexOf(system) === -1 &&
      systems[system].development !== "undeveloped"
    ) {
      let fillColor = "#666";
      if (system === "Sol") {
        fillColor = "#FAF152";
      } else {
        switch (systems[system].development) {
          case "core":
            fillColor = "#D9E147";
            break;
          case "colony":
            fillColor = "#FF721C";
            break;
          case "outpost":
            fillColor = "#FFCDAE";
            break;
          case "enclave":
            fillColor = "#FF8597";
            break;
          default:
            break;
        }
      }

      doneSystems.push(system);
      let extraSystems = [];
      let extraSystemNames = [];
      if (systems[system].connections) {
        for (let connection in systems[system].connections) {
          if (systems[system].connections[connection] === 0) {
            extraSystems.push(
              <p key={"extraSystem_" + connection.replace(/\s+/g, "")}>
                <b>{connection}</b>
                {systems[connection].commonNames ? <br /> : ""}
                {systems[connection].commonNames}
              </p>
            );
            extraSystemNames.push(connection);
            doneSystems.push(connection);
          }
        }
      }

      let tmpPopup = [];
      let tmpEventHandlers = {
        mouseover: (e) => {
          mouseOverSystem(e, system);
        },
        mouseout: (e) => {
          mouseOutSystem(e);
        },
      };
      if (extraSystemNames.length === 0) {
        tmpEventHandlers = {
          ...tmpEventHandlers,
          click: () => {
            setMarker(system);
          },
        };
      } else {
        let tmpTargets = [
          <div key={"target_" + system}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setMarker(system);
              }}
            >
              {system}
            </a>
            {systems[system].commonNames
              ? " (" + systems[system].commonNames + ")"
              : ""}
          </div>,
        ];
        extraSystemNames.forEach((extraSystem) => {
          tmpTargets.push(
            <div key={"target_" + extraSystem.replace(/\s+/g, "")}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setMarker(extraSystem);
                }}
              >
                {extraSystem}
              </a>
              {systems[extraSystem].commonNames
                ? " (" + systems[extraSystem].commonNames + ")"
                : ""}
            </div>
          );
        });
        tmpPopup.push(
          <Popup
            pane="popup"
            minWidth={100}
            key={"popup_" + system.replace(/\s+/g, "")}
          >
            <b>Select target</b>
            {tmpTargets}
          </Popup>
        );
      }

      colonyMarkers.push(
        <CircleMarker
          center={[systems[system].y, systems[system].x]}
          weight={1}
          radius={7}
          color={"#000"}
          fillColor={fillColor}
          opacity={1}
          fillOpacity={1}
          key={"system_" + system.replace(/\s+/g, "")}
          eventHandlers={tmpEventHandlers}
        >
          <Tooltip
            pane={"tooltip"}
            offset={[10, 0]}
            key={"tooltip_" + system.replace(/\s+/g, "")}
          >
            <div key={"target_" + system.replace(/\s+/g, "")}>
              <b>{system}</b>
              {systems[system].commonNames ? <br /> : ""}
              {systems[system].commonNames ? systems[system].commonNames : ""}
              <i>
                {systems[system].development ? <br /> : ""}
                {systems[system].development
                  ? systems[system].development.charAt(0).toUpperCase() +
                    systems[system].development.slice(1) +
                    " system"
                  : ""}
                {systems[system].development !== "core" &&
                systems[system].nations
                  ? " for " + systems[system].nations
                  : ""}
              </i>
            </div>
            {extraSystems}
          </Tooltip>
          {tmpPopup}
        </CircleMarker>
      );
    }

    if (systems[system].connections) {
      let source = [systems[system].y, systems[system].x];
      for (let connection in systems[system].connections) {
        let pathName = `${systems[system].y}${systems[system].x}-${systems[connection].y}${systems[connection].x}`;
        let isDevelopedPath =
          systems[system].development !== "undeveloped" &&
          systems[connection].development !== "undeveloped"
            ? true
            : false;
        if (donePaths.indexOf(pathName) === -1 && isDevelopedPath === true) {
          let tmpPath = (
            <Polyline
              positions={[
                source,
                [systems[connection].y, systems[connection].x],
              ]}
              color={isDevelopedPath ? "#666" : "#bbb"}
              key={
                "path_" +
                system.replace(/\s+/g, "") +
                connection.replace(/\s+/g, "")
              }
              weight={isDevelopedPath ? 2 : 1}
              developedPath={isDevelopedPath}
            >
              <Tooltip pane={"tooltip"} offset={[10, 0]}>
                <b>
                  {system} <>&#8596;</> {connection}
                </b>
                <br />
                {systems[system].connections[connection].toFixed(2)} ly
                <br />
                <>&#916; </>
                {Math.round(
                  Math.sqrt(
                    (systems[system].u - systems[connection].u) ** 2 +
                      (systems[system].v - systems[connection].v) ** 2 +
                      (systems[system].w - systems[connection].w) ** 2
                  )
                )}{" "}
                m/s
              </Tooltip>
            </Polyline>
          );

          if (
            systems[system].development !== "undeveloped" &&
            systems[connection].development !== "undeveloped"
          ) {
            colonyPaths.push(tmpPath);
          } else {
            nonColonyPaths.push(tmpPath);
          }

          donePaths.push(pathName);
          donePaths.push(
            `${systems[connection].y}${systems[connection].x}-${systems[system].y}${systems[system].x}`
          );
        }
      }
    }
  }

  for (let system in systems) {
    if (
      doneSystems.indexOf(system) === -1 &&
      systems[system].development === "undeveloped"
    ) {
      doneSystems.push(system);
      let extraSystems = [];
      let extraSystemNames = [];
      if (systems[system].connections) {
        for (let connection in systems[system].connections) {
          if (systems[system].connections[connection] === 0) {
            extraSystems.push(
              <p key={"extraSystem_" + connection.replace(/\s+/g, "")}>
                <b>{connection}</b>
                {systems[connection].commonNames ? <br /> : ""}
                {systems[connection].commonNames}
              </p>
            );
            extraSystemNames.push(connection);
            doneSystems.push(connection);
          }
        }
      }

      let tmpPopup = [];
      let tmpEventHandlers = {
        mouseover: (e) => {
          mouseOverSystem(e, system);
        },
        mouseout: (e) => {
          mouseOutSystem(e);
        },
      };
      if (extraSystemNames.length === 0) {
        tmpEventHandlers = {
          ...tmpEventHandlers,
          click: () => {
            setMarker(system);
          },
        };
      } else {
        let tmpTargets = [
          <div key={"target_" + system}>
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                setMarker(system);
              }}
            >
              {system}
            </a>
            {systems[system].commonNames
              ? " (" + systems[system].commonNames + ")"
              : ""}
          </div>,
        ];
        extraSystemNames.forEach((extraSystem) => {
          tmpTargets.push(
            <div key={"target_" + extraSystem.replace(/\s+/g, "")}>
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  setMarker(extraSystem);
                }}
              >
                {extraSystem}
              </a>
              {systems[extraSystem].commonNames
                ? " (" + systems[extraSystem].commonNames + ")"
                : ""}
            </div>
          );
        });
        tmpPopup.push(
          <Popup
            pane="popup"
            minWidth={100}
            key={"popup_" + system.replace(/\s+/g, "")}
          >
            <b>Select target</b>
            {tmpTargets}
          </Popup>
        );
      }

      let tmpColony = (
        <CircleMarker
          center={[systems[system].y, systems[system].x]}
          weight={1}
          radius={5}
          color={"#333"}
          fillColor={"#fff"}
          opacity={1}
          fillOpacity={1}
          key={"system_" + system.replace(/\s+/g, "")}
          eventHandlers={tmpEventHandlers}
        >
          <Tooltip pane={"tooltip"} offset={[10, 0]}>
            <p>
              <b>{system}</b>
              <br />
              {systems[system].commonNames ? systems[system].commonNames : ""}
            </p>
            {extraSystems}
          </Tooltip>
          {tmpPopup}
        </CircleMarker>
      );

      systems[system].starGroup === systems["Sol"].starGroup
        ? nonColonyMarkers.push(tmpColony)
        : nonReachableMarkers.push(tmpColony);
    }

    if (systems[system].connections) {
      let source = [systems[system].y, systems[system].x];
      for (let connection in systems[system].connections) {
        let pathName = `${systems[system].y}${systems[system].x}-${systems[connection].y}${systems[connection].x}`;
        let isDevelopedPath =
          systems[system].development !== "undeveloped" &&
          systems[connection].development !== "undeveloped"
            ? true
            : false;
        if (donePaths.indexOf(pathName) === -1 && isDevelopedPath === false) {
          let tmpPath = (
            <Polyline
              positions={[
                source,
                [systems[connection].y, systems[connection].x],
              ]}
              color={isDevelopedPath ? "#666" : "#bbb"}
              key={
                "path_" +
                system.replace(/\s+/g, "") +
                connection.replace(/\s+/g, "")
              }
              weight={isDevelopedPath ? 2 : 1}
              developedPath={isDevelopedPath}
            >
              <Tooltip pane={"tooltip"} offset={[10, 0]}>
                <b>
                  {system} <>&#8596;</> {connection}
                </b>
                <br />
                {systems[system].connections[connection].toFixed(2)} ly
                <br />
                <>&#916; </>
                {Math.round(
                  Math.sqrt(
                    (systems[system].u - systems[connection].u) ** 2 +
                      (systems[system].v - systems[connection].v) ** 2 +
                      (systems[system].w - systems[connection].w) ** 2
                  )
                )}{" "}
                m/s
              </Tooltip>
            </Polyline>
          );

          if (
            systems[system].development !== "undeveloped" &&
            systems[connection].development !== "undeveloped"
          ) {
            colonyPaths.push(tmpPath);
          } else if (systems[system].starGroup === systems["Sol"].starGroup) {
            nonColonyPaths.push(tmpPath);
          } else {
            nonReachablePaths.push(tmpPath);
          }

          donePaths.push(pathName);
          donePaths.push(
            `${systems[connection].y}${systems[connection].x}-${systems[system].y}${systems[system].x}`
          );
        }
      }
    }
  }

  return (
    <MapContainer
      zoomSnap={0.25}
      bounds={[
        [-33, -45.5],
        [24, 22],
      ]}
      style={{ height: "100vh", width: "100wh" }}
      attributionControl={false}
      whenCreated={setMap}
      tap={false}
    >
      <Pane name="popup" style={{ zIndex: 1100 }}></Pane>
      <Pane name="tooltip" style={{ zIndex: 1000 }}></Pane>
      <Pane name="markers" style={{ zIndex: 175 }}>
        {from !== null && <Marker position={from.position} />}
        {to !== null && <Marker position={to.position} />}
      </Pane>
      <Pane name="colonies" style={{ zIndex: 150 }}>
        {colonyMarkers}
      </Pane>
      <Pane name="nonColonies" style={{ zIndex: 100 }}>
        {nonColonyMarkers}
      </Pane>
      <Pane name="nonReachableSystems" style={{ zIndex: 95 }}>
        {state.useNonReachableSystems && nonReachableMarkers}
      </Pane>
      <Pane name="pathPaths" style={{ zIndex: 90 }}>
        {pathPaths}
      </Pane>
      <Pane name="hoverPaths" style={{ zIndex: 75 }}>
        {hoverPaths}
      </Pane>
      <Pane name="colonyPaths" style={{ zIndex: 50 }}>
        {colonyPaths}
      </Pane>
      <Pane name="nonColonyPaths" style={{ zIndex: 25 }}>
        {nonColonyPaths}
      </Pane>
      <Pane name="nonReachablePaths" style={{ zIndex: 15 }}>
        {state.useNonReachableSystems && nonReachablePaths}
      </Pane>
      {videoPlaying && (
        <ReactPlayer
          url="https://www.youtube.com/watch?v=88zdAv3e1ao"
          width="160px"
          height="90px"
          playing={videoPlaying}
          controls={false}
          volume={0.2}
          style={{ position: "absolute", top: "10px", right: "10px" }}
        />
      )}
    </MapContainer>
  );
}
